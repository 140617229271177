import axios, { AxiosResponse } from 'axios';
import { soundControllerUrl } from './helpers';

export const getEvents = async (): Promise<string[]> => {
	try {
		const result: AxiosResponse = await axios.get(
			soundControllerUrl + "/get-events/",
		)
		return result.data.events
    } catch (error: any) {
		throw new Error(error)
    }
}