import { useEffect, useState } from 'react';
import { Event } from '../components/event';
import { getEvents } from '../api';


export default function Events() {
    const [ events, setEvents ] = useState<string[]>([]);

    useEffect(() => {
        getEvents()
            .then(res => {
                setEvents(res);
            })
            .catch(err => console.log('err', err));
            
    }, [])
    return (
        <main style={{ padding: "1rem 0" }}>
            <h2>Events:</h2>
            { events.map( (event, index) => <Event key={index} event={event} />) }
        </main>
    );
}